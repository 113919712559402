<template>
  <div class="view-header">
    <div class="title-wrap">
      <h3 class="title">{{post.postTitle}}</h3>
      <p class="subtitle">
        <span class="text" v-if="boardMst.userScrtYn == 'Y'">{{post.userNickNm}}</span>
        <span class="text" v-else >{{post.deptNm}}  {{post.jbgdNm}} {{post.jbpsNm}} {{post.lrnerNm}}</span>
      </p>
      <p class="subtitle">
        <span class="text">작성 {{ timestampToDateFormat(post.regDt, 'yyyy.MM.dd hh:mm') }}</span>
        <span class="text" v-if="post.mdfcnDt">수정 {{ timestampToDateFormat(post.mdfcnDt, 'yyyy.MM.dd hh:mm') }}</span>
      </p>
      <p class="subtitle">
        <span class="text">조회 {{ post.inqCnt }}</span>
      </p>
      <div v-if="customInfos.length > 0" class="mt-3" >
        <div v-for="customInfo in customInfos" :key="customInfo.boardCstmDataSn">
          <p v-if="customInfo.value" class="mb-2"><span class="text-muted me-2">{{customInfo.itemNm}}</span><span>{{customInfo.value}}</span></p>
        </div>
      </div>
    </div>
  </div>

  <div class="view-body">
    <!-- boardLayoutCd 에 따라 thumbnail 표시-->
    <div class="img-fluid mb-3" v-if="boardMst.boardLayoutCd=='thumbList' && post.thumbFileUrl"><img :src="post.thumbFileUrl" alt="썸네일 이미지" class="w-100"/></div>
    <!-- 본문 내용 -->
    <div class="view-content ck-content" v-html="post.postCn"></div>
    <!-- 해시태그 -->
    <div v-if="post.hashTag" class="view-component my-5">
      <template v-for="(tag,idx) in post.hashTag.split(hashTagSeparator)" :key="idx">
        <span v-if="tag" class="text-gold fw-bold me-2">#{{tag}}</span>
      </template>
    </div>
    <slot name="view-body-end"></slot>
  </div>
</template>
<script>
import {hashTagSeparator} from "@/assets/js/modules/board/board-common";
import {convertToStorageBytes, timestampToDateFormat} from "@/assets/js/util";

export default {
  name: 'BoardViewContent',
  props: {
    boardMst: Object,
    post: Object,
    files: Array,
    customInfos: Array,
    downloadAtch: Function,
  },
  setup() {
    return {
      hashTagSeparator,
      timestampToDateFormat,
      convertToStorageBytes,
    }
  }
}
</script>