<template>
  <LxpMobileHeader v-if="!isLoading && !toggle.shareModal" title="">
    <template v-slot:left>
      <div class="util util-history-back">
        <a @click="goBack" class="util-actions util-actions-history-back">
          <i class="icon-history-back"></i>
        </a>
      </div>
    </template>
    <template v-slot:right>
      <div v-if="showHottipHeader" class="util util-btn">
        <a @click="toggle.shareModal = !toggle.shareModal" class="util-actions util-actions-share">
          공유<i class="icon-share opacity-25"></i>
        </a>
      </div>
      <div v-if="post.postFileAtchYn == 'Y'" class="util util-download">
        <a href="javascript:" @click="showAttachments = true" class="util-actions util-actions-download">
          첨부파일<i class="icon-download"></i>
        </a>
      </div>
    </template>
  </LxpMobileHeader>

  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-board-view" v-if="!isLoading && !toggle.shareModal" style="margin-bottom:60px;">
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="board-view-container">
        <BoardViewContent :board-mst="boardMst" :post="post" :files="files" :custom-infos="customInfos" :download-atch="downloadAtch">
          <template v-slot:view-body-end>
            <div class="board-view-body view-component">
              <div class="like-container">
                <!-- 좋아요/담기 -->
                <div v-if="boardMst.postLikeYn == 'Y' || boardMst.postPutYn == 'Y'" class="like d-flex flex-column align-items-center">
                  <img class="ms-0" src="../../../../assets/images/ssl/ic_like.png" alt="">
                  <div>
                    <button :class="{'is-active': post.isLike == 'Y'}" @click="actLike(post)" v-if="boardMst.postLikeYn == 'Y'">
                      <span>좋아요</span>&nbsp;<span class="like-num">{{post.likeCnt}}</span>
                    </button>
                    <button :class="{'is-active': post.isPut == 'Y'}" @click="actPut(post)" v-if="boardMst.postPutYn == 'Y'">
                      <span v-if="post.isPut == 'Y'">담기 취소</span>
                      <span v-else>담기</span>
                    </button>
                  </div>
                </div>
                <!-- 이전글 / 다음글 -->
                <div v-if="post.postLvl === 0 && (post.prevPostSnSecuKey || post.nextPostSnSecuKey)" class="d-flex justify-content-center mt-3">
                  <button class="kb-btn kb-btn-xs kb-btn-secondary rounded-circle me-3" style="width: 35px; height: 35px;" @click="goToView(post.prevPostSnSecuKey, boardMst.boardId)" :disabled="!post.prevPostSnSecuKey">
                    <span class="text">◀</span>
                  </button>
                  <button class="kb-btn kb-btn-xs kb-btn-secondary rounded-circle" style="width: 35px; height: 35px;" @click="goToView(post.nextPostSnSecuKey, boardMst.boardId)" :disabled="!post.nextPostSnSecuKey">
                    <span class="text">▶</span>
                  </button>
                </div>
              </div>
            </div>
            <!-- 글 평가 -->
            <board-evaluation v-if="boardMst.postEvalYn == 'Y'" :com-post-sn="post.comPostSn" :post-star-score="post.starScore" />
          </template>
        </BoardViewContent>

        <div class="view-bottom">
          <!-- 글 수정 -->
          <button v-if="((boardMst.postRewrtYn == 'Y' && post.postLvl == 0) || (boardMst.ansRewrtYn == 'Y' && post.postLvl > 0)) && isWriter" class="kb-btn kb-btn-sm kb-btn-outline rounded-lg kb-btn-w100" @click="goToEdit(post.boardSecuKey, boardMst.boardId)"><span class="text">수정</span></button>
          <!-- 글 삭제 -->
          <button v-if="((boardMst.postRewrtYn == 'Y' && post.postLvl == 0) || (boardMst.ansRewrtYn == 'Y' && post.postLvl > 0)) && isWriter" class="kb-btn kb-btn-sm kb-btn-outline rounded-lg kb-btn-w100" @click="clickDelete"><span class="text">삭제</span></button>
          <!-- 답글 쓰기 -->
          <button v-if="boardMst.ansWrtYn == 'Y' && validTarget(boardMst.ansWrtTrgt, isOper)" class="kb-btn kb-btn-sm kb-btn-outline rounded-lg kb-btn-w100" @click="addAnswer"><span class="text">답글쓰기</span></button>
        </div>
      </div>
    </div>

    <!-- 댓글 -->
    <common-board-comment v-if="boardMst.cmntWrtYn == 'Y'" :board-mst-map="boardMst" :board-secu-key="post.boardSecuKey" :com-post-sn="post.comPostSn" :is-oper="isOper" :show-empty="false" />
  </main>

  <!-- 하단 첨부파일 리스트 -->
  <div class="main-content main-component" v-if="showAttachments">
    <div class="kb-form-layer is-active">
      <div class="layer-container" @click="showAttachments = false">
        <div class="layer">
          <div class="kb-form-layer-options">
            <div class="layer-option">
              <ul class="layer-option-list">
                <li v-for="(file,idx) in files" :key="idx" class="layer-option-item">
                  <a class="layer-option-link" :title="file.fileNm" @click.stop="downloadAtch(file)">
                    <span class="layer-option-text">{{ file.fileNm != null ? file.fileNm : '-' }}</span>
                    <span class="text-muted">{{ file.fileSz > 0 ? convertToStorageBytes(file.fileSz) : 0 }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <BoardShareMobile v-model="toggle.shareModal" :board-mst="boardMst" :post="post" />
</template>

<script>
import {boardViewSetup} from "@/assets/js/modules/board/board-common";
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader.vue";
import BoardViewContent from "@/components/board/mobile/BoardViewContent.vue";
import BoardEvaluation from "@/components/board/BoardEvaluation";
import CommonBoardComment from "@/components/board/CommonBoardComment";
import BoardShareMobile from "@/components/board/mobile/BoardShareMobile.vue";

export default {
  name: 'BoardView',
  components: {LxpMobileHeader, BoardViewContent, BoardEvaluation, CommonBoardComment, BoardShareMobile},
  setup: boardViewSetup
}
</script>