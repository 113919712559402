<template>
  <LxpMobileHeader v-if="modelValue" :title="title">
    <template v-slot:left>
      <div class="util util-history-back">
        <a @click="closeModal" class="util-actions util-actions-history-back">
          <i class="icon-history-back"></i>
        </a>
      </div>
    </template>
  </LxpMobileHeader>
  <main v-if="modelValue" class="kb-main" id="kb-hrdcloud">
    <div class="main-content main-component">
      <article class="content-section">
        <div class="kb-form-fields">
          <div class="kb-form-row">
            <div class="kb-form-column">
              <div class="kb-form-search-field reverse">
                <input v-model.trim="search.deptNm" type="text" class="kb-form-search-input" placeholder="부점 및 부서명 검색" @keyup.enter="clickSearch">
                <button class="kb-form-search-btn" @click="clickSearch"><i class="icon-search"></i></button>
              </div>
            </div>
          </div>
          <div class="kb-form-row">
            <div class="kb-form-column">
              <div class="kb-form-search-field reverse">
                <input v-model.trim="search.lrnerNm" type="text" class="kb-form-search-input" placeholder="직원명 및 직원번호 검색" @keyup.enter="clickSearch">
                <button class="kb-form-search-btn" @click="clickSearch"><i class="icon-search"></i></button>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article class="content-section">
        <div class="kb-form-fields">
          <div class="kb-form-field field-border-0">
            <div class="kb-form-row">
              <label class="kb-form-label"><span class="kb-form-label-text">검색결과</span></label>
              <span class="kb-form-label-subtext text-nowrap">{{paging.totalCount}}명</span>
            </div>
            <div class="kb-form-row">
              <div v-if="results.length > 0" class="kb-form-check-group">
                <div v-for="(result, index) in results" :key="index" class="kb-form-check">
                  <input v-model="targets" type="checkbox" :id="`chk_search_${index}`" class="kb-form-check-input" :value="result" @click.stop="checkTargetsLength" :disabled="result.lrnerId == session.lrnerId">
                  <label :for="`chk_search_${index}`" class="kb-form-check-label text-skip overflow-x-auto" style="max-width: calc(100% - 35px);">
                    {{ result.lrnerNm }} ・ {{ result.lrnerId }} ・ {{ result.deptNm }} ・ {{ getJbgdCd(result.jbgdCd) }}
                  </label>
                </div>
                <div class="pt-3"><CommonPaginationFront :paging="paging" :page-func="pageFunc" /></div>
              </div>
              <div v-else>
                <span class="text text-muted">검색 결과가 없습니다.</span>
              </div>
            </div>
          </div>
          <div class="kb-form-field">
            <div class="kb-form-row">
              <label class="kb-form-label"><span class="kb-form-label-text">공유대상</span></label>
              <span class="kb-form-label-subtext text-nowrap">{{targets.length}}명</span>
            </div>
            <div class="kb-form-row">
              <div v-if="targets.length > 0" class="kb-form-check-group">
                <div v-for="(target, index) in targets" :key="index" class="kb-form-check">
                  <input type="checkbox" :id="`chk_target_${index}`" class="kb-form-check-input" :checked="true" @click.prevent.stop="clearTarget(index)">
                  <label :for="`chk_target_${index}`" class="kb-form-check-label text-skip overflow-x-auto" style="max-width: calc(100% - 35px);">
                    {{ target.lrnerNm }} ・ {{ target.lrnerId }} ・ {{ target.deptNm }} ・ {{ getJbgdCd(target.jbgdCd) }}
                  </label>
                </div>
              </div>
              <div v-else>
                <span class="text text-muted">공유 대상이 없습니다.</span>
              </div>
            </div>
          </div>
          <div class="kb-form-field">
            <div class="kb-form-row"><label class="kb-form-label"><span class="kb-form-label-text">공유 메시지</span></label></div>
            <div class="kb-form-row">
              <textarea v-model.trim="param.message" class="kb-form-control" placeholder="전송할 메시지를 입력해주세요." />
            </div>
          </div>
          <div class="kb-form-field">
            <div class="kb-form-row"><label class="kb-form-label"><span class="kb-form-label-text">공유방법</span></label></div>
            <div class="kb-form-row">
              <div class="kb-form-check-group">
                <div v-for="(notiPfm, index) in notiPfms" :key="index" class="kb-form-check">
                  <input v-model="param.notiPfmCds" type="checkbox" class="kb-form-check-input" :id="`chk_pfm_${index}`" :value="notiPfm.dcd">
                  <label :for="`chk_pfm_${index}`" class="kb-form-check-label"><span class="text">{{notiPfm.name}}</span></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <div class="page-buttons mt-0" @click="clickShare">
        <button class="kb-btn kb-btn-primary">공유하기</button>
      </div>
    </div>
  </main>
</template>

<script>
import {boardShareSetup} from "@/assets/js/modules/board/board-share-setup";
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader.vue";
import CommonPaginationFront from '@/components/CommonPaginationFront';

export default {
  name: 'BoardShareMobile',
  components: {LxpMobileHeader, CommonPaginationFront},
  props: {
    modelValue: Boolean,
    boardMst: Object,
    post: Object
  },
  emits: ['update:modelValue'],
  setup: boardShareSetup
}
</script>